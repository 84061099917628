<template>
  <div class="grid row">
    <div class="flex xs12">
      <va-card title="Редагування новини" class="fill-height">
        <div>
          <div class="row">
            <div class="flex xs12">
              <va-tabs v-model="tabValue" style="width: 100%; min-width: 250px; margin-bottom: 25px;">
                <va-tab
                  v-for="title in tabTitles"
                  :key="title"
                >
                  {{title}}
                </va-tab>
              </va-tabs>
              <div class="flex xs12" v-if="tabValue === 0">
                <va-card class="mb-3" title="Image *">
                  <div v-if="imgExisting">
                    <a style="display: block;" target="_blank" :href="imgExisting"><img style="max-width: 400px;" :src="imgExisting"></a>
                    <div><va-button small color="danger" @click="imgExisting = deleteImage(imgId) ? '' : imgExisting">Видалити</va-button></div>
                  </div>
                  <span>Завантажити нове зображення</span>
                  <va-file-upload
                    @input="delete errors.image"
                    type="gallery"
                    dropzone
                    v-model="img"
                    style="margin-bottom: 25px;"
                  />
                  <div v-if="errors.image" style="color: red;">{{ this.errors.image[0] }}</div>
                  <va-button @click="uploadImage('image', 'NewsImage', 'News[image]', img[0])">Завантажити</va-button>
                </va-card>
                <va-card class="mb-3" title="Image for mini card">
                  <div v-if="minicardImgExisting">
                    <a style="display: block;" target="_blank" :href="minicardImgExisting"><img style="max-width: 400px;" :src="minicardImgExisting"></a>
                    <div><va-button small color="danger" @click="minicardImgExisting = deleteImage(minicardImgId) ? '' : minicardImgExisting">Видалити</va-button></div>
                  </div>
                  <span>Завантажити нове зображення</span>
                  <va-file-upload
                    type="gallery"
                    dropzone
                    @input="delete errors.miniImage"
                    v-model="minicardImg"
                    style="margin-bottom: 25px;"
                  />
                  <div v-if="errors.miniImage" style="color: red;">{{ this.errors.miniImage[0] }}</div>
                  <va-button @click="uploadImage('miniImage', 'NewsMiniImage', 'News[miniImage]', minicardImg[0])">Завантажити</va-button>
                </va-card>
                <va-input
                  label="Label *"
                  v-model="label"
                  :messages="['The recommended number of characters is 15']"
                  @input="delete errors.label"
                  :error="!!errors.label"
                  :error-messages="errors.label"
                />
                <va-input
                  label="Alias"
                  v-model="alias"
                  :messages="['The recommended number of characters is 15']"
                  @input="delete errors.alias"
                  :error="!!errors.alias"
                  :error-messages="errors.alias"
                />
                <va-date-picker
                  label="Published at *"
                  :config="{enableTime: true, locale: {firstDayOfWeek: 1}, dateFormat: 'd.m.Y H:i:S'}"
                  v-model="published_at"
                  weekDays
                />
                <div style="margin-bottom: 15px;">
                  <span>Content *</span>
                  <ckeditor v-model="content" :editor="editor" :config="editorConfig"></ckeditor>
                </div>
                <span v-if="errors.content" style="color: red;">{{ this.errors.content[0] }}</span>
                <va-input
                  label="Position"
                  v-model.number="position"
                  @input="delete errors.position"
                  :error="!!errors.position"
                  :error-messages="errors.position"
                />
                <va-toggle label="Published" small v-model="published"/>
                <va-button @click="submit()">Save</va-button>
              </div>
              <div class="flex xs12" v-if="tabValue === 1">
                <va-input
                  label="Title"
                  v-model="seoTitle"
                />
                <va-input
                  label="Description"
                  v-model="seoDescription"
                />
                <va-input
                  label="Keywords"
                  v-model="seoKeywords"
                />
                <div style="margin-bottom: 15px;">
                  <ckeditor v-model="seoText" :editor="editor" :config="editorConfig"></ckeditor>
                </div>
                <va-card class="mb-3" title="Image">
                  <div v-if="seoImageExisting">
                    <a style="display: block;" target="_blank" :href="seoImageExisting"><img style="max-width: 400px;" :src="seoImageExisting"></a>
                    <div><va-button small color="danger" @click="seoImageExisting = deleteImage(seoImageId) ? '' : seoImageExisting">Видалити</va-button></div>
                  </div>
                  <span>Завантажити нове зображення</span>
                  <va-file-upload
                    type="gallery"
                    dropzone
                    v-model="seoImage"
                    style="margin-bottom: 25px;"
                  />
                  <va-button @click="uploadImage('image', 'SeoEntityImage', 'SeoEntity[image]', seoImage[0], 'common\\models\\SeoEntity')">Завантажити</va-button>
                </va-card>
                <va-button @click="submit()">Save</va-button>
              </div>
            </div>
          </div>
        </div>
      </va-card>
    </div>
  </div>
</template>

<script>
import * as axios from 'axios'
import CKEditor from '@ckeditor/ckeditor5-vue'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import '@ckeditor/ckeditor5-build-classic/build/translations/uk'
import Vue from 'vue'

export default {
  name: 'grid',
  components: {
    ckeditor: CKEditor.component,
  },
  data () {
    return {
      img: [],
      imgId: '',
      imgExisting: '',
      minicardImg: [],
      minicardImgId: '',
      minicardImgExisting: '',
      label: '',
      alias: '',
      published_at: '08-05-2020 14:10:00',
      content: '',
      position: '',
      published: false,

      seoId: '',
      seoTitle: '',
      seoDescription: '',
      seoKeywords: '',
      seoText: '',
      seoImage: [],
      seoImageId: '',
      seoImageExisting: '',
      editor: ClassicEditor,
      editorConfig: {
        language: 'uk',
        simpleUpload: {
          uploadUrl: `${process.env.VUE_APP_AUTOSTRADA_API_URL}/uk/admin/editor/upload`,
          headers: {
            Authorization: localStorage.getItem('token'),
          },
        },
      },

      sign: '',

      errors: [],

      tabTitles: ['Main', 'Seo'],
      tabValue: 0,
    }
  },
  created () {
    if (!this.$attrs.id) {
      const today = new Date()
      this.published_at = today.toLocaleDateString('uk-UA') + ' ' + today.toLocaleTimeString('uk-UA')
      const generateRandomString = (length = 6) => Math.random().toString(20).substr(2, length)
      this.sign = generateRandomString(32)
    }
    this.fetch()
  },
  methods: {
    uploadImage (attr, entityAttribute, param, file, model = 'backend\\modules\\news\\models\\News') {
      if (!file) {
        return
      }
      const formData = new FormData()
      formData.append('model_name', model)
      formData.append('attribute', attr)
      formData.append('entity_attribute', entityAttribute)
      formData.append(param, file)
      formData.append('file_id', '0')
      formData.append('multiple', '0')
      if (entityAttribute === 'SeoEntityImage') {
        formData.append('id', this.seoId)
      } else {
        if (this.$attrs.id) {
          formData.append('id', this.$attrs.id)
        } else {
          formData.append('sign', this.sign)
        }
      }

      axios.post(`${process.env.VUE_APP_AUTOSTRADA_API_URL}/${this.locale}/admin/upload`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
        .then(response => {
          this.showToast('Successful upload')
        })
        .catch(error => {
          console.log(error)
          this.showToast('Error')
        })
    },
    async deleteImage (id) {
      return axios.delete(`${process.env.VUE_APP_AUTOSTRADA_API_URL}/${this.locale}/admin/upload/${id}`)
        .then(() => {
          this.showToast('Successfully deleted')
        })
        .catch(() => {
          this.showToast('Error')
        })
    },
    submit () {
      const data = {
        label: this.label,
        alias: this.alias,
        published_at: this.published_at,
        content: this.content,
        position: this.position,
        published: !!this.published,
        sign: this.sign ? this.sign : null,

        SeoEntity: {
          title: this.seoTitle,
          description: this.seoDescription,
          keywords: this.seoKeywords,
          text: this.seoText,
        },
      }
      if (this.$attrs.id) { // update
        axios.put(`${process.env.VUE_APP_AUTOSTRADA_API_URL}/${this.locale}/admin/news/${this.$attrs.id}`, data)
          .then(response => {
            this.$router.push({ name: 'autostrada-news' })
            this.showToast('Success')
          })
          .catch(error => {
            if (error.response.status === 422) {
              this.errors = error.response.data.errors
            }
            console.log(error)
            this.showToast('Error')
          })
      } else {
        axios.post(`${process.env.VUE_APP_AUTOSTRADA_API_URL}/${this.locale}/admin/news`, data)
          .then(response => {
            this.$router.push({ name: 'autostrada-news' })
            this.showToast('Success')
          })
          .catch(error => {
            if (error.response.status === 422) {
              this.errors = error.response.data.errors
            }
            console.log(error)
            this.showToast('Error')
          })
      }
    },
    fetch () {
      if (this.$attrs.id) {
        axios.get(`${process.env.VUE_APP_AUTOSTRADA_API_URL}/${this.locale}/admin/news/${this.$attrs.id}`)
          .then(response => {
            console.log(response.data)
            this.label = response.data.news.label
            this.alias = response.data.news.alias
            this.position = response.data.news.position
            this.published = !!response.data.news.published
            this.content = response.data.news.content
            const date = new Date(response.data.news.published_at * 1000)
            this.published_at = date.toLocaleDateString('uk-UA') + ' ' + date.toLocaleTimeString('uk-UA')

            this.seoId = response.data.seo.id
            this.seoTitle = response.data.seo.title
            this.seoDescription = response.data.seo.description
            this.seoKeywords = response.data.seo.keywords
            this.seoText = response.data.seo.text
            this.seoImageExisting = response.data.seo.image ? process.env.VUE_APP_AUTOSTRADA_ADMIN + response.data.seo.image : response.data.seo.image
            this.seoImageId = response.data.seo.imageId

            this.imgExisting = process.env.VUE_APP_AUTOSTRADA_ADMIN + response.data.news.image.originalSrc
            this.imgId = response.data.news.imageId
            const imgMini = response.data.news.mini_image
            if (imgMini) {
              this.minicardImgExisting = process.env.VUE_APP_AUTOSTRADA_ADMIN + imgMini.originalSrc
              this.minicardImgId = response.data.news.mini_image_id
            }
          })
          .catch(error => {
            console.log(error)
            this.showToast('Error')
          })
      }
    },
  },
  computed: {
    computedStyle () {
      return {
        backgroundColor: this.$themes.primary,
      }
    },
    locale () {
      return Vue.i18n.locale()
    },
  },
  watch: {
    locale: function (value) {
      this.fetch()
    },
  },
}
</script>

<style lang="scss">
.grid {
  &__container {
    min-height: 3rem;
    color: $white;
    border-radius: 0.5rem;
  }
}

.va-file-upload-gallery-item {
  flex-basis: unset;
  max-width: 400px;
  width: unset;
}
</style>
